<template>
  <BCard>
    <div class="flex mb-3">
      <BButton
        variant="primary"
        size="sm"
        class="rounded-lg mr-2"
        @click="$router.back()"
      >
        <b-icon-chevron-left />
      </BButton>
      <span class="text-[24px] font-semibold my-auto text-[#626262]">Campaign Detail</span>
    </div>
    <BRow>
      <BCol lg="6">
        <BRow class="mb-2">
          <BCol>
            <div class="border p-1 rounded-xl">
              <div class="flex justify-between mb-1">
                <span class="text-black font-semibold">Jenis Campaign</span>
                <span class="font-normal">Blast Email</span>
              </div>
              <div class="flex justify-between mb-1">
                <span class="text-black font-semibold">Tipe Email</span>
                <span class="font-normal">{{ data.type_email }}</span>
              </div>
              <div class="flex justify-between mb-1">
                <span class="text-black font-semibold">Email Pengirim</span>
                <span class="font-normal">{{ data.sender_email }}</span>
              </div>
              <div class="flex justify-between mb-1">
                <span class="text-black font-semibold">Template Terpilih</span>
                <span class="font-normal">{{ data.chosen_template }}</span>
              </div>
            </div>
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <div class="border p-1 rounded-xl">
              <span class="text-black font-semibold">Target Pengguna</span>
              <div class="flex mb-1">
                <div
                  v-for="(item, idx) in data.service_type"
                  :key="idx"
                  class="flex items-center"
                >
                  <img
                    :src="imageService(item)"
                    alt="Komerce"
                    width="30"
                  >
                  <span class="capitalize text-black">{{ item }}</span>
                </div>
              </div>
              <span class="text-black font-semibold">Judul Campaign</span>
              <div class="text-[#333333] mb-1">
                {{ data.title }}
              </div>
              <span class="text-black font-semibold">Isi Campaign</span>
              <div
                class="text-[#333333]"
                v-html="data.content"
              />
            </div>
          </BCol>
        </BRow>
      </BCol>
      <BCol lg="6">
        <div class="border p-1 rounded-xl">
          <span class="text-black font-semibold">Email Delivery Metrics</span>
          <img
            src="https://storage.googleapis.com/komerce/assets/illustration/comming_soon.svg"
            alt="Komerce"
            class="mt-1"
          >
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'

export default {
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      path: this.$route.path,
      data: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const url = `/sender/api/v1/campaign/get-detail/${this.id}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data = data
        })
    },
    imageService(value) {
      if (value === 'komship') {
        return 'https://storage.googleapis.com/komerce/assets/logo/Product-Komship.svg'
      }
      return 'https://storage.googleapis.com/komerce/assets/Affiliate/Komerce%20Affiliate_-01.png'
    },
  },
}
</script>
